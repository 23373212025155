import { useState } from 'react';
import cx from 'classnames';

import EmployeeTitle from 'common/EmployeeTitle/EmployeeTitle';
import { generateAvatar } from 'utils/generateUI';

import { arrowUp } from 'constants/icons';
import { TEAM_BUDGET_TABLE_HEADERS } from 'constants/peopleTableHeaders';
import { TeamBudgeting } from 'models/interfaces/budgeting.interface';

import styles from './teamBudgetStyles.module.scss';

const BudgetRow = ({ team }: { team: TeamBudgeting }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <tr
        onClick={() => setIsOpen(!isOpen)}
        className={cx(styles.teamTitleRow, team.type ? styles[team.type?.title.toLowerCase()] : '')}
      >
        <td>
          {team.children?.length || team.budget?.length ? (
            <span className={cx(styles.arrow, isOpen ? styles.arrowOpened : '')}>{arrowUp}</span>
          ) : null}
          {team.title}
        </td>
        {TEAM_BUDGET_TABLE_HEADERS.map(
          (header, index) => index + 1 !== TEAM_BUDGET_TABLE_HEADERS.length && <td key={header.id}></td>
        )}
      </tr>

      {isOpen && (
        <>
          {team.budget?.map((item, index) => (
            <tr
              key={index}
              className={item.employee?.id === team.teamleadId && !item.resourceRequest ? styles.teamLead : ''}
            >
              <td>
                {item.employee ? (
                  <div className={styles.employee}>
                    {generateAvatar(item.employee.firstName, item.employee.lastName)}
                    {`${item.employee.firstName} ${item.employee.lastName}`}
                    {item.employee.id === team.teamleadId && !item.resourceRequest && (
                      <EmployeeTitle employee={{ ...item.employee, isTeamlead: true }} />
                    )}
                  </div>
                ) : null}
              </td>
              <td>{item.grade}</td>
              <td>
                {item.project ? (
                  <a href={item.resourceRequest.link} target="_blank">
                    {item.project}
                  </a>
                ) : (
                  '-'
                )}
              </td>
              <td>{item.source || '-'}</td>
              <td>{item.estimatedHours}</td>
              <td>{item.estimatedBudget}</td>
              <td>{item.estimatedCost}</td>
              <td>{item.factualHours}</td>
              <td>{item.factualBudget}</td>
            </tr>
          ))}

          {team.children?.map(child => (
            <BudgetRow key={child.id} team={child} />
          ))}
        </>
      )}
    </>
  );
};

export default BudgetRow;
