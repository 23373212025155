import { COLORS } from 'config/theme';
import { TeamWithBudget, BudgetDataT, TeamBudgeting } from 'models/interfaces/budgeting.interface';
import TeamModel from 'models/team.model';
import { BudgetingInfo } from 'store/budgeting';

export const getCoreTeams = (teams: TeamModel[], teamsToDisplay?: string): TeamModel[] => {
  if (!teamsToDisplay) {
    return teams.filter((team: TeamModel) => !team.parentId);
  } else {
    const teamsArr = teamsToDisplay.split(',');

    if (teamsArr.length === 1) {
      const selectedTeam = teams.find(team => team.id === +teamsToDisplay);
      return selectedTeam ? [selectedTeam] : [];
    } else {
      return teams.filter(team => teamsArr.includes(String(team.id)) && !teamsArr.includes(String(team.parentId)));
    }
  }
};

const addBudgetToTeam = (currentTeam: TeamWithBudget, budgetItemsMap: Map<number, BudgetDataT[]>): TeamBudgeting => {
  const team = {
    ...currentTeam,
    budget: currentTeam?.budget || [],
  };

  if (budgetItemsMap.has(team.id)) {
    const teamItems = budgetItemsMap.get(team.id);
    const regularItems: BudgetDataT[] = []; // not Team Lead RRs
    let teamLeadItem: BudgetDataT | null = null;

    teamItems?.forEach(item => {
      if (team.teamleadId === item.employee?.id && !item.resourceRequest) {
        teamLeadItem = item;
      } else {
        regularItems.push(item);
      }
    });

    team.budget.push(...regularItems);
    if (teamLeadItem) {
      team.budget.push(teamLeadItem);
    }
  }

  team.children = team.children
    ?.map(child => addBudgetToTeam(child, budgetItemsMap))
    .filter(child => child.hasBudgetData || (child.budget && child.budget.length > 0));
  team.children?.sort(
    (a, b) => (a.children && a.children.length > 0 ? 1 : 0) - (b.children && b.children.length > 0 ? 1 : 0)
  );

  team.hasBudgetData = team.budget.length > 0 || team.children?.some(child => child.hasBudgetData);

  return team as TeamBudgeting;
};

export const addBudgetingDataToTeams = (teams: TeamWithBudget[] = [], budget: BudgetDataT[]): TeamBudgeting[] => {
  const budgetItemsMap = new Map<number, BudgetDataT[]>();

  budget.forEach(item => {
    const teamId = item.team.id;
    if (!budgetItemsMap.has(teamId)) {
      budgetItemsMap.set(teamId, []);
    }
    budgetItemsMap.get(teamId)?.push(item);
  });

  return teams.map(team => addBudgetToTeam(team, budgetItemsMap)).filter(team => team.hasBudgetData);
};

export const getBudgetTotals = (budgetData: BudgetingInfo | null) => {
  return [
    {
      title: 'Estimated Budget',
      value: `${budgetData?.estimatedBudget || 0}$`,
      color: COLORS.darkGray,
    },
    {
      title: 'Factual Budget',
      value: `${budgetData?.factualBudget || 0}$`,
      color: COLORS.orange,
    },
    {
      title: 'Estimated Cost',
      value: `${budgetData?.estimatedCost || 0}$`,
      color: COLORS.teal,
    },
    {
      title: 'Estimated Hours',
      value: `${budgetData?.estimatedHours || 0}h`,
      color: COLORS.purple,
    },
    {
      title: 'Factual Hours',
      value: `${budgetData?.factualHours || 0}h`,
      color: COLORS.blue,
    },
  ];
};
