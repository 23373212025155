import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import { FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';

import FormikDropdown from 'common/Formik/FormikDropdown';
import Drawer from './Drawer';
import ActionButtons from './ActionButtons';
import SelectedWorkforce from './SelectedWorkforce';
import DeleteModal from '../DeleteModal';
import WorkforceFormInputs from './WorkforceFormInputs';

import { getProjectsList } from 'store/projects/projects.thunk';
import { getProjectInitRequests } from 'store/projectInitRequests/projectInitRequests.thunk';
import { clearProjectInitRequests } from 'store/projectInitRequests';
import { createResourceRequestsBulk } from 'store/resourceRequestsBulk/resourceRequestsBulk.thunk';
import { getTeamsListThunk } from 'store/teams/teams.thunk';
import { AppState } from 'store/configureStore';

import { bluePlus } from 'constants/icons';
import { DATE_PICKER } from 'constants/common';
import {
  CreateRRmodalProps,
  CreateRrInitialValues,
  CreateRrT,
  WorkforceRR,
} from 'models/interfaces/createRR.interface';

import styles from '../../../components/modal.module.scss';
import drawerStyles from './styles.module.scss';

const CREATE_RR_SCHEMA = Yup.object({
  project: Yup.object().nullable().required('Field required'),
  pir: Yup.object().nullable().required('Field required'),
  workforceArr: Yup.array(),
  workforce: Yup.object({
    teamId: Yup.number().positive('Field required').required('Field required'),
    fte: Yup.number().required('Field required'),
    serviceType: Yup.string().required('Field required'),
    workforce: Yup.string().nullable(),
    dateRange: Yup.array()
      .of(Yup.date().nullable(true))
      .required('Field required')
      .test('date-range-test', 'Please select both start and end date', array => {
        return array?.length === 2 && array[0] !== null && array[1] !== null;
      }),

    billingSource: Yup.string().nullable(),
    grade: Yup.string().nullable(),
    description: Yup.string(),
  }),
});

const setTouchedInputs = (touched: boolean) => {
  return {
    workforce: {
      teamId: touched,
      fte: touched,
      serviceType: touched,
      billingSource: touched,
      dateRange: touched,
      grade: touched,
    },
  };
};

const CreateRRmodal = ({ onClose, currentProject, currentPir, callback, setOpenModal }: CreateRRmodalProps) => {
  const dispatch = useDispatch();

  const { projects } = useSelector((state: AppState) => state.projectsReducer);
  const { projectInitRequests } = useSelector((state: AppState) => state.projectInitRequestsReducer);

  const [openAddWorkforce, setOpenAddWorkforce] = useState(false);
  const [openEditWorkforce, setOpenEditWorkforce] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(0);

  useEffect(() => {
    dispatch(getTeamsListThunk({ planning: true }));
  }, []);

  const onProjectSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement;
      dispatch(getProjectsList({ page: 1, size: 50, search: target.value, statusCategory: 'workforce-requestable' }));
    }, 250),
    []
  );

  const onPirSearch = useCallback(
    debounce((event: KeyboardEvent, values: CreateRrInitialValues) => {
      const target = event.target as HTMLInputElement;
      dispatch(
        getProjectInitRequests({
          page: 1,
          size: 50,
          projectKey: values.project?.projectKey,
          search: target.value,
          statusCategory: 'workforce-requestable',
        })
      );
    }, 250),
    []
  );

  const initialValues: CreateRrInitialValues = {
    project: currentProject || null,
    pir: currentPir || null,
    workforceArr: [],
    workforce: {
      teamId: 0,
      fte: 1,
      serviceType: '',
      workforce: '',
      dateRange: [
        currentPir?.periodStartDate ? new Date(currentPir.periodStartDate) : null,
        currentPir?.periodStartDate ? new Date(currentPir.periodStartDate) : null,
      ],
      billingSource: currentPir?.billingSource || '',
      grade: '',
      description: '',
    },
  };

  const onSubmit = (values: CreateRrInitialValues) => {
    const requestBody: CreateRrT[] = values.workforceArr.map((workforce: WorkforceRR) => {
      const [startDate, endDate] = workforce.dateRange;
      const rr: CreateRrT = {
        teamId: workforce.teamId,
        fte: workforce.fte,
        billingSource: workforce.billingSource || null,
        serviceType: workforce.serviceType,
        description: workforce.description,
        workforce: workforce.workforce,
        grade: workforce.grade || null,
        projectKey: values.project?.projectKey || '',
        pir: values.pir?.issueKey || '',
        fromDate: format(startDate as Date, DATE_PICKER.dateFormatToPayload),
        toDate: format(endDate as Date, DATE_PICKER.dateFormatToPayload),
      };
      if (rr.fte > 1 || !rr.workforce) {
        delete rr.workforce;
      }
      return rr;
    });
    dispatch(createResourceRequestsBulk({ values: requestBody, callback }));
    setOpenModal(false);
  };

  return createPortal(
    <Formik initialValues={initialValues} validationSchema={CREATE_RR_SCHEMA} onSubmit={onSubmit}>
      {({ values, setFieldValue, validateForm, setTouched }) => (
        <Form>
          <Drawer
            className={cx(
              drawerStyles.drawer,
              openAddWorkforce || openEditWorkforce ? drawerStyles.disabled : drawerStyles.active,
              'text-left'
            )}
            title="Create RR"
            onClose={onClose}
          >
            <div className={styles.inputWrap}>
              <div className={drawerStyles.requiredHint}>
                Required fields are marked with an asterisk<span className={styles.required}>*</span>
              </div>

              <div className="filters-block">
                <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
                  <p className="label-wrapper">
                    Project<span className={styles.required}>*</span>
                  </p>
                  <FormikDropdown
                    name="project"
                    placeholder="Search and Select Project"
                    data={projects || []}
                    defaultValue={currentProject || ''}
                    onDropdownSearch={onProjectSearch}
                    handleChange={() => {
                      setFieldValue('pir', null);
                      setFieldValue('workforceArr', initialValues.workforceArr);
                      dispatch(clearProjectInitRequests());
                    }}
                    textField="name"
                    addFilter={true}
                    disabled={!!currentPir}
                  />
                </div>
              </div>

              {values.project && (
                <>
                  <div className="filters-block">
                    <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
                      <p className="label-wrapper">
                        Pir<span className={styles.required}>*</span>
                      </p>
                      <FormikDropdown
                        name="pir"
                        placeholder="Search and Select PIR"
                        data={projectInitRequests || []}
                        defaultValue={currentPir || ''}
                        onDropdownSearch={event => onPirSearch(event, values)}
                        handleChange={value => {
                          if (typeof value !== 'string') {
                            setFieldValue(
                              'workforceArr',
                              values.workforceArr?.map(item => ({
                                ...item,
                                dateRange: [new Date(value.periodStartDate), new Date(value.periodEndDate)],
                              }))
                            );
                          }
                        }}
                        textField="summary"
                        addFilter={true}
                        disabled={!!currentPir}
                      />
                    </div>
                  </div>

                  {values.pir && (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue('workforce', {
                            ...initialValues.workforce,
                            dateRange: [new Date(values.pir!.periodStartDate), new Date(values.pir!.periodEndDate)],
                          });
                          setTouched(setTouchedInputs(false));
                          setOpenAddWorkforce(true);
                        }}
                        className={drawerStyles.addBtn}
                      >
                        {bluePlus} Add Workforce
                      </button>

                      <div className={drawerStyles.workforceItems}>
                        {values.workforceArr.map((workforce: WorkforceRR, index: number) => (
                          <SelectedWorkforce
                            key={index}
                            workforce={workforce}
                            onDelete={() => setOpenDeleteModal(index + 1)}
                            onEdit={() => {
                              setFieldValue('workforce', workforce);
                              setTouched(setTouchedInputs(false));

                              setOpenEditWorkforce(index + 1);
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <ActionButtons
              className={drawerStyles.buttonWrapper}
              cancelText="Cancel"
              submitText="Create"
              onCancel={onClose}
              isSubmitBtn
              disabled={!values.workforceArr.length}
            />
          </Drawer>

          <FieldArray name="workforceArr">
            {({ push, remove, replace }) =>
              openAddWorkforce || openEditWorkforce ? (
                <Drawer
                  className={cx(drawerStyles.drawer, drawerStyles.active, 'text-left')}
                  title={`${openAddWorkforce ? 'Add' : 'Edit'} Workforce`}
                  onClose={() => {
                    setOpenAddWorkforce(false);
                    setOpenEditWorkforce(0);
                  }}
                >
                  <WorkforceFormInputs values={values} setFieldValue={setFieldValue} />

                  <ActionButtons
                    className={drawerStyles.buttonWrapper}
                    cancelText="Cancel"
                    submitText={openAddWorkforce ? 'Add' : 'Edit'}
                    onCancel={() => (openAddWorkforce ? setOpenAddWorkforce(false) : setOpenEditWorkforce(0))}
                    onSubmit={() => {
                      validateForm().then(errors => {
                        if (Object.keys(errors).length === 0) {
                          if (openAddWorkforce) {
                            push(values.workforce);
                            setOpenAddWorkforce(false);
                          } else {
                            replace(openEditWorkforce - 1, values.workforce);
                            setOpenEditWorkforce(0);
                          }
                        } else {
                          setTouched(setTouchedInputs(true));
                        }
                      });
                    }}
                  />
                </Drawer>
              ) : openDeleteModal ? (
                <DeleteModal
                  onClose={() => setOpenDeleteModal(0)}
                  onDelete={() => {
                    remove(openDeleteModal - 1);
                    setOpenDeleteModal(0);
                  }}
                  title={`Workforce ${openDeleteModal}`}
                  itemToDelete="this Workforce"
                />
              ) : null
            }
          </FieldArray>
        </Form>
      )}
    </Formik>,
    document.body
  );
};

export default CreateRRmodal;
