import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTeamsBudgeting } from './budgeting.thunk';
import { BudgetDataT } from 'models/interfaces/budgeting.interface';

export interface BudgetingInfo {
  items: BudgetDataT[];
  estimatedBudget: number | null;
  factualBudget: number | null;
  estimatedHours: number | null;
  factualHours: number | null;
  estimatedCost: number | null;
}

interface BudgetingInitialState {
  loading: boolean;
  error: null | string;
  budgetData: BudgetingInfo | null;
}

const initialState: BudgetingInitialState = {
  loading: false,
  error: null,
  budgetData: null,
};

const budgetingSlice = createSlice({
  name: 'budgeting',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTeamsBudgeting.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeamsBudgeting.fulfilled, (state, action: PayloadAction<BudgetingInfo>) => {
        state.budgetData = action.payload;
        state.loading = false;
      })
      .addCase(getTeamsBudgeting.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default budgetingSlice.reducer;
